import BaseClinic from "../../components/layout/page/base-clinic"
import { graphql } from "gatsby"
import React from "react"

const WorkInHandClinic = ({ data }) => {
  const {
    markdownRemark: {
      html,
      frontmatter: { title },
    },
  } = data

  return <BaseClinic title={title} html={html} />
}

export default WorkInHandClinic

export const query = graphql`
  query WorkInHandQuery {
    markdownRemark(frontmatter: { name: { eq: "work-in-hand" } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
